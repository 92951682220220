import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   meta: {
  //     title: 'Asset management'
  //   },
  //   component: () => import('../views/Home.vue')
  // },
  // {
  //   path: '*',
  //   name: 'PageNotFound',
  //   meta: {
  //     title: 'Page not found'
  //   },
  //   component: () => import('../views/PageNotFound.vue')
  // }, 

  // ricognizione
  { path: '/connectRfidDevice', component: () => import('../views/ricognizione/RfidManager.vue')},
  { path: '/print_labels', component: () => import('../views/ricognizione/PrintLabels.vue')},
  { path: '/print_rooms_labels', component: () => import('../views/ricognizione/PrintRoomsLabels.vue')},
  { path: '/prima_ricognizione', component: () => import('../views/ricognizione/PrimaRicognizione.vue')},
  { path: '/ricognizione', component: () => import('../views/ricognizione/Ricognizione.vue')},
  { path: '/ammricognizione', component: () => import('../views/ricognizione/AmmRicognizione.vue')},
  { path: '/reporicognizione', component: () => import('../views/ricognizione/Report.vue')},
  ///////////////
  
  { path: '/old', component: () => import('../views/Old.vue')},
  { path: '/users', component: () => import('../views/Users.vue')},
  { path: '/users/:matr', component: () => import('../views/User.vue')},
  { path: '/mydata', component: () => import('../views/MyData.vue')},
  { path: '/myrooms', component: () => import('../views/MyRooms.vue')},
  { path: '/myassets', component: () => import('../views/MyAssets.vue')},
  { path: '/mycontainers', component: () => import('../views/MyContainers.vue')},
  { path: '/myhousings', component: () => import('../views/MyHousings.vue')},
  { path: '/mypurchases', component: () => import('../views/MyPurchases.vue')},
  { path: '/mypurchase/:purchase', component: () => import('../views/MyPurchase.vue')},
  { path: '/rooms', component: () => import('../views/Rooms.vue')},
  { path: '/purchases', component: () => import('../views/acquisti/Purchases.vue')},
  { path: '/purchase/:id', component: () => import('../views/acquisti/SinglePurchase.vue')},
  { path: '/rooms/:id', component: () => import('../views/Room.vue')},
  { path: '/assets/:id', component: () => import('../views/Asset.vue')},
  { path: '/assets', component: () => import('../views/Assets.vue')},
  { path: '/assetssearch/last', component: () => import('../views/AssetsSearch.vue')},
  { path: '/assetssearch/:year/:number', component: () => import('../views/AssetsSearch.vue')},
  { path: '/assetssearch', component: () => import('../views/AssetsSearch.vue')},
  { path: '/purchasessearch', component: () => import('../views/acquisti/PurchasesSearch.vue')},
  { path: '/usernotfound', component: () => import('../views/UserNotFound.vue')},
  { path: '/sync', component: () => import('../views/Sync.vue')},
  { path: '/texting', component: () => import('../views/Texting.vue')},
  { path: '/containers', component: () => import('../views/Containers.vue')},
 // { path: '/home', component: () => import('../views/Home.vue')},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // TODO check for authentication and authorization

  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  next();
})

export default router
